/* eslint-disable */

<template>
  <div class="vx-col">
    <vx-card class="mb-base">
      <div class="">
        <movable-popup
          refVal="movableWiFiControllerRef"
          id="firewall_two_popup"
          title="Cisco 5516-X NGFW Firewall Appliance - Drag Here To Move"
          :active="firewall_two"
          v-on:close="closePopup('firewall_two')"
          style="width: 55vw !important; max-width: 1200px !important; display: none"
        >
          <template v-slot:content>
            <div class="tenth-table-block">
              <div class="tenth-table-header-2">
                <div class="tenth-firewall-column w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Rule Name</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Source</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Destination</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                    <p class="tenth-table-title">Service<br /></p>
                  </div>
                  <div class="w-sim-legacy-col w-sim-legacy-col-1">
                    <p class="tenth-table-title">Action<br /></p>
                  </div>
                </div>
              </div>
              <div class="tenth-table-body">
                <div v-for="answer in firewall_two_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <h5>{{ answer.rule }}</h5>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'threeSource' + answer.id" class="ninth-select w-select">
                      <option v-for="item in source_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'threeDest' + answer.id" class="ninth-select w-select">
                      <option v-for="item in dest_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'threeService' + answer.id" class="ninth-select w-select">
                      <option v-for="item in service_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'threeAction' + answer.id" class="ninth-select w-select">
                      <option v-for="item in action_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </movable-popup>
        <movable-popup
          refVal="movableWiFiControllerRef"
          id="firewall_three_popup"
          title="Cisco Firepower 2110 Firewall Appliance - Drag Here To Move"
          :active="firewall_three"
          v-on:close="closePopup('firewall_three')"
          style="width: 55vw !important; max-width: 1200px !important; display: none"
        >
          <template v-slot:content>
            <div class="tenth-table-block">
              <div class="tenth-table-header-2">
                <div class="tenth-firewall-column w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Rule Name</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Source</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Destination</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                    <p class="tenth-table-title">Service<br /></p>
                  </div>
                  <div class="w-sim-legacy-col w-sim-legacy-col-1">
                    <p class="tenth-table-title">Action<br /></p>
                  </div>
                </div>
              </div>
              <div class="tenth-table-body">
                <div v-for="answer in firewall_three_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <h5>{{ answer.rule }}</h5>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'twoSource' + answer.id" class="ninth-select w-select">
                      <option v-for="item in source_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'twoDest' + answer.id" class="ninth-select w-select">
                      <option v-for="item in dest_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'twoService' + answer.id" class="ninth-select w-select">
                      <option v-for="item in service_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'twoAction' + answer.id" class="ninth-select w-select">
                      <option v-for="item in action_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </movable-popup>
        <movable-popup
          refVal="movableWiFiControllerRef"
          id="firewall_one_popup"
          title="Cisco Firepower 1140 Firewall Appliance - Drag Here To Move"
          :active="firewall_one"
          v-on:close="closePopup('firewall_one')"
          style="width: 55vw !important; max-width: 1200px !important; display: none"
        >
          <template v-slot:content>
            <div class="tenth-table-block">
              <div class="tenth-table-header-2">
                <div class="tenth-firewall-column w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Rule Name</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Source</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <p class="tenth-table-title">Destination</p>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-2">
                    <p class="tenth-table-title">Service<br /></p>
                  </div>
                  <div class="w-sim-legacy-col w-sim-legacy-col-1">
                    <p class="tenth-table-title">Action<br /></p>
                  </div>
                </div>
              </div>
              <div class="tenth-table-body">
                <div v-for="answer in firewall_one_answers" :key="answer.id" class="tenth-firewall-column-body w-sim-legacy-row">
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <h5>{{ answer.rule }}</h5>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'oneSource' + answer.id" class="ninth-select w-select">
                      <option v-for="item in source_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'oneDest' + answer.id" class="ninth-select w-select">
                      <option v-for="item in dest_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'oneService' + answer.id" class="ninth-select w-select">
                      <option v-for="item in service_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                  <div class="tenth-firewall-sec w-sim-legacy-col w-sim-legacy-col-3">
                    <select :id="'oneAction' + answer.id" class="ninth-select w-select">
                      <option v-for="item in action_options" :key="item">{{ item }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </movable-popup>
        <div class="w-full flex justify-center relative click-item-container">
          <div class="image-container" style="position: relative">
            <img
              src="https://connect-cdn.intellectualpoint.com/assets/images/simulations/security-plus/335106580255080448/firewall-grid.png"
              draggable="false"
              style="width: 100%; height: 100%"
            />
            <span
              class="click-item"
              style="left: 57.3%; top: 2.3%; border-radius: 0.6rem; width: 13rem; height: 8.5rem"
              @click="show_firewall('firewall_one')"
            ></span>
            <span
              class="click-item"
              style="left: 57.3%; top: 43%; border-radius: 0.6rem; width: 13rem; height: 8.5rem"
              @click="show_firewall('firewall_two')"
            ></span>
            <span
              class="click-item"
              style="left: 57.3%; top: 63.6%; border-radius: 0.6rem; width: 13rem; height: 8.5rem"
              @click="show_firewall('firewall_three')"
            ></span>
          </div>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import Prism from 'vue-prism-component';
import MovablePopup from '../../../../../../../../../components/popup/MovablePopup';

export default {
  data() {
    return {
      dataLocal: JSON.parse(JSON.stringify(this.data)),
      draggingCard: false,

      firewall_one: true,
      firewall_two: true,
      firewall_three: true,

      current_page: 0,

      source_options: ['ANY', '10.10.10.1/26', '10.80.80.1/28', '10.80.50.1/28'],

      dest_options: ['ANY', '10.10.10.1/26', '10.80.80.1/28', '10.80.50.1/28'],

      service_options: ['ANY', 'DNS', 'HTTP', 'HTTPS', 'TELNET', 'SSH'],

      action_options: ['PERMIT', 'DENY'],

      firewall_one_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS EGRESS',
          source: '10.10.10.1/26',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '3', rule: 'Management', source: 'ANY', dest: '10.10.10.1/26', service: 'SSH', action: 'PERMIT' },
        {
          id: '4',
          rule: 'HTTPS INGRESS',
          source: 'ANY',
          dest: '10.10.10.1/26',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '5',
          rule: 'HTTP INGRESS',
          source: 'ANY',
          dest: '10.10.10.1/26',
          service: 'HTTP',
          action: 'DENY',
        },
      ],

      firewall_two_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS EGRESS',
          source: '10.80.50.1/28',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '3', rule: 'Management', source: 'ANY', dest: '10.80.50.1/28', service: 'SSH', action: 'PERMIT' },
        {
          id: '4',
          rule: 'HTTPS INGRESS',
          source: 'ANY',
          dest: '10.80.50.1/28',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        { id: '5', rule: 'HTTP INGRESS', source: 'ANY', dest: '10.80.50.1/28', service: 'HTTP', action: 'DENY' },
      ],

      firewall_three_answers: [
        { id: '1', rule: 'DNS Rule', source: 'ANY', dest: 'ANY', service: 'DNS', action: 'PERMIT' },
        {
          id: '2',
          rule: 'HTTPS EGRESS',
          source: '10.80.80.1/28',
          dest: 'ANY',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '3',
          rule: 'Management',
          source: 'ANY',
          dest: '10.80.80.1/28',
          service: 'SSH',
          action: 'PERMIT',
        },
        {
          id: '4',
          rule: 'HTTPS INGRESS',
          source: 'ANY',
          dest: '10.80.80.1/28',
          service: 'HTTPS',
          action: 'PERMIT',
        },
        {
          id: '5',
          rule: 'HTTP INGRESS',
          source: 'ANY',
          dest: '10.80.80.1/28',
          service: 'HTTP',
          action: 'DENY',
        },
      ],
    };
  },
  methods: {
    show_firewall(x) {
      if (x === 'firewall_one') {
        document.getElementById('firewall_one_popup').style.display = 'block';
        document.getElementById('firewall_two_popup').style.display = 'none';
        document.getElementById('firewall_three_popup').style.display = 'none';
      }
      if (x === 'firewall_two') {
        document.getElementById('firewall_one_popup').style.display = 'none';
        document.getElementById('firewall_two_popup').style.display = 'block';
        document.getElementById('firewall_three_popup').style.display = 'none';
      }
      if (x === 'firewall_three') {
        document.getElementById('firewall_one_popup').style.display = 'none';
        document.getElementById('firewall_two_popup').style.display = 'none';
        document.getElementById('firewall_three_popup').style.display = 'block';
      }
    },
    closePopup(popup) {
      if (popup === 'firewall_one_popup') {
        document.getElementById('firewall_one_popup').style.display = 'none';
      }
      if (popup === 'firewall_two_popup') {
        document.getElementById('firewall_two_popup').style.display = 'none';
      }
      if (popup === 'firewall_three_popup') {
        document.getElementById('firewall_three_popup').style.display = 'none';
      }
    },
    markSimulation() {
      let totalScore = 0;

      this.firewall_one_answers.forEach((x) => {
        if (x.source && x.source === document.getElementById(`oneSource${x.id}`).value) {
          document.getElementById(`oneSource${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`oneSource${x.id}`).style.borderColor = '#c93738';
        }
        if (x.dest && x.dest === document.getElementById(`oneDest${x.id}`).value) {
          document.getElementById(`oneDest${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`oneDest${x.id}`).style.borderColor = '#c93738';
        }
        if (x.service && x.service === document.getElementById(`oneService${x.id}`).value) {
          document.getElementById(`oneService${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`oneService${x.id}`).style.borderColor = '#c93738';
        }
        if (x.action && x.action === document.getElementById(`oneAction${x.id}`).value) {
          document.getElementById(`oneAction${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`oneAction${x.id}`).style.borderColor = '#c93738';
        }
      });

      this.firewall_two_answers.forEach((x) => {
        if (x.source && x.source === document.getElementById(`twoSource${x.id}`).value) {
          document.getElementById(`twoSource${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`twoSource${x.id}`).style.borderColor = '#c93738';
        }
        if (x.dest && x.dest === document.getElementById(`twoDest${x.id}`).value) {
          document.getElementById(`twoDest${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`twoDest${x.id}`).style.borderColor = '#c93738';
        }
        if (x.service && x.service === document.getElementById(`twoService${x.id}`).value) {
          document.getElementById(`twoService${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`twoService${x.id}`).style.borderColor = '#c93738';
        }
        if (x.action && x.action === document.getElementById(`twoAction${x.id}`).value) {
          document.getElementById(`twoAction${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`twoAction${x.id}`).style.borderColor = '#c93738';
        }
      });
      this.firewall_three_answers.forEach((x) => {
        if (x.source && x.source === document.getElementById(`threeSource${x.id}`).value) {
          document.getElementById(`threeSource${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`threeSource${x.id}`).style.borderColor = '#c93738';
        }
        if (x.dest && x.dest === document.getElementById(`threeDest${x.id}`).value) {
          document.getElementById(`threeDest${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`threeDest${x.id}`).style.borderColor = '#c93738';
        }
        if (x.service && x.service === document.getElementById(`threeService${x.id}`).value) {
          document.getElementById(`threeService${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`threeService${x.id}`).style.borderColor = '#c93738';
        }
        if (x.action && x.action === document.getElementById(`threeAction${x.id}`).value) {
          document.getElementById(`threeAction${x.id}`).style.borderColor = '#1ecb5a';
          totalScore++;
        } else {
          document.getElementById(`threeAction${x.id}`).style.borderColor = '#c93738';
        }
      });

      if (totalScore === this.dataLocal.simulation.totalScore) {
        return this.$emit('simulation_full_correct', totalScore);
      }

      this.$emit('simulation_getting_there', totalScore);
    },
  },
  components: {
    Prism,
    MovablePopup,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.w-select {
  font-weight: 800;
}

@import '@/views/platform/modules/simulations/legacy/css/CompTIA/Security+/Lgx335106580255080448.scss';
</style>
